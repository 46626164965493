import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Hero: React.FC = () => {
  const textCanvasRef = useRef<HTMLCanvasElement>(null);
  const tempCanvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [textRendered, setTextRendered] = useState(false);
  const [canvasSize, setCanvasSize] = useState({ width: 600, height: 200 });
  const animationRef = useRef<number>();
  
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  // Update canvas size based on container width
  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        let newWidth = Math.min(containerWidth * 0.9, 800); // Max width of 800px
        let newHeight = (newWidth / 3); // Maintain 3:1 aspect ratio
        
        // Minimum sizes
        newWidth = Math.max(newWidth, 300);
        newHeight = Math.max(newHeight, 100);
        
        setCanvasSize({ width: newWidth, height: newHeight });
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  const drawText = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);
    
    // Scale font sizes based on canvas width
    const titleFontSize = Math.max(width * 0.067, 24); // 40px at 600px width
    const subtitleFontSize = Math.max(width * 0.033, 16); // 20px at 600px width
    
    // Main title
    ctx.font = `bold ${titleFontSize}px Inter, sans-serif`;
    ctx.fillStyle = '#1a237e';
    ctx.textAlign = 'left';
    const title = 'Transforming Education';
    ctx.fillText(title, width * 0.033, height * 0.3); // 20px at 600px width
    ctx.fillText('Through Technology', width * 0.033, height * 0.55);

    // Subtitle
    ctx.font = `${subtitleFontSize}px Inter, sans-serif`;
    ctx.fillStyle = '#4a4a4a';
    const subtitle = 'SanJae Educational Resources empowers';
    ctx.fillText(subtitle, width * 0.033, height * 0.75);
    ctx.fillText('learners and educators with innovative solutions.', width * 0.033, height * 0.9);
  };

  // Text animation effect
  useEffect(() => {
    const canvas = textCanvasRef.current;
    const tempCanvas = tempCanvasRef.current;
    if (!canvas || !tempCanvas || !inView) return;

    // Cancel any existing animation
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;
    tempCanvas.width = canvasSize.width;
    tempCanvas.height = canvasSize.height;

    const ctx = canvas.getContext('2d');
    const tempCtx = tempCanvas.getContext('2d');
    if (!ctx || !tempCtx) return;

    // Draw initial text on temp canvas
    drawText(tempCtx, canvasSize.width, canvasSize.height);

    // Animation variables
    let pixelSize = 40;
    let frame = 0;
    const totalFrames = 30;
    const startTime = performance.now();
    const duration = 1000; // 1 second animation

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      frame = Math.floor(progress * totalFrames);

      if (frame >= totalFrames) {
        ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);
        ctx.drawImage(tempCanvas, 0, 0);
        setTextRendered(true);
        return;
      }

      const currentPixelSize = pixelSize * (1 - progress);

      ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);
      
      if (currentPixelSize > 1) {
        const scaledWidth = Math.floor(canvasSize.width / currentPixelSize);
        const scaledHeight = Math.floor(canvasSize.height / currentPixelSize);

        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(
          tempCanvas,
          0, 0, canvasSize.width, canvasSize.height,
          0, 0, scaledWidth, scaledHeight
        );
        ctx.drawImage(
          canvas,
          0, 0, scaledWidth, scaledHeight,
          0, 0, canvasSize.width, canvasSize.height
        );
      } else {
        ctx.drawImage(tempCanvas, 0, 0);
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    setTextRendered(false);
    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [inView, canvasSize]);

  return (
    <section className="relative min-h-[40vh] flex items-center justify-center mobile-landscape:mt-[7.5vh]">
      <div className="absolute inset-0 -z-10 bg-white"></div>
      <div 
        ref={containerRef}
        className="container mx-auto flex justify-center items-center px-4 w-full sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12"
      >
        <motion.div 
          ref={ref}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="text-center w-full"
        >
          <canvas
            ref={textCanvasRef}
            style={{ 
              width: '100%',
              height: 'auto',
              maxWidth: `${canvasSize.width}px`,
            }}
            className="mx-auto"
          />
          <canvas
            ref={tempCanvasRef}
            style={{ display: 'none' }}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
