import React from 'react';
import { useModal } from '../../context/ModalContext';
import { MODAL_CONTENT } from '../../constants/modalContent';
import styles from './Solutions.module.css';
import { useInView } from 'react-intersection-observer';

const Solutions: React.FC = () => {
  const { openModal } = useModal();
  const { ref, inView } = useInView({
    threshold: 0.05,
    rootMargin: '-10% 0px -10% 0px',
    triggerOnce: true
  });

  const handleNYCEnglishClick = () => {
    openModal(
      "NYC English",
      [
        {
          title: "Overview",
          content: "NYC English is a supplemental instructional software program that blends holistic teaching methodologies through differentiated instruction for individual students, small group learning, or classroom instruction."
        },
        {
          title: "Version 3.0 Features",
          content: "Version 3.0 offers more than 900 professionally produced seamless, embedded high-definition videos with high-quality sound. Cartoon free real-life scenarios portray articulated, grammatically correct English spoken by multicultural American native speakers in a clean, intuitive interface."
        },
        {
          title: "Interactive Learning",
          content: "This program supports students' efforts to acquire the English language through recording, playback, reading aloud, listening, and immediate feedback. Additionally, there is pronunciation analysis and playback of the student's recordings. All of the student's work is saved in their portfolio for future reference."
        },
        {
          title: "Writing and Assessment",
          content: "Writing prompts are added as students advance to higher levels, and all student work is recorded and displayed in student, teacher, and administrator dashboards within the program through confidential identifiers. As needed, all pages can be screenshot and printed for hard copy activities. These writing exercises utilize sophisticated algorithms to automatically grade the students' writings."
        },
        {
          title: "Comprehensive Learning Support",
          content: "NYC English provides sufficient modeling of foundational skills and provides student practice with a review of mastery of new English skills including specific vocabulary to support social and emotional learning, as well as instructional and academic language including advanced reading, writing and additional academic vocabulary practice."
        },
        {
          title: "Educational Design",
          content: "NYC English provides age and skill-appropriate content for students of all ages. The application offers scripted lessons and resources proven through evidence-based strategies for teachers to use during group instruction. The program is designed by educators to be effective for students, teachers, and administrators."
        }
      ],
      [
        {
          id: '1M940YQI5NIIR1yWIOpBG1pRerFPZ6GIh',
          thumbnailUrl: 'https://drive.google.com/thumbnail?id=1M940YQI5NIIR1yWIOpBG1pRerFPZ6GIh',
          title: 'Overview'
        },
        {
          id: '1M4daRRr7aPWs5Vz7WRQx_XwoHVmnRodN',
          thumbnailUrl: 'https://drive.google.com/thumbnail?id=1M4daRRr7aPWs5Vz7WRQx_XwoHVmnRodN',
          title: 'Product Demo'
        },
        {
          id: '1MGQ1kAS4tsWRFyDPMVRwAUwv3B7szOtF',
          thumbnailUrl: 'https://drive.google.com/thumbnail?id=1MGQ1kAS4tsWRFyDPMVRwAUwv3B7szOtF',
          title: 'Writing Exercises'
        },
        {
          id: '1MGSSV6zQwGrb8P7kO0nOXXAUosGnYVZF',
          thumbnailUrl: 'https://drive.google.com/thumbnail?id=1MGSSV6zQwGrb8P7kO0nOXXAUosGnYVZF',
          title: 'Academic Vocabulary'
        }
      ]
    );
  };

  const handleMoneyVehicleClick = () => {
    openModal(
      MODAL_CONTENT.moneyVehicle.title,
      MODAL_CONTENT.moneyVehicle.cards,
      [
        {
          id: 'BkYqE43wIUM',
          thumbnailUrl: `https://img.youtube.com/vi/BkYqE43wIUM/maxresdefault.jpg`,
          title: 'Money Vehicle Overview'
        }
      ]
    );
  };

  const handleCWPublicationsClick = () => {
    openModal(
      MODAL_CONTENT.cwPublications.title,
      MODAL_CONTENT.cwPublications.cards,
      [
        {
          id: 'sX2BeDzX8E0',
          thumbnailUrl: `https://img.youtube.com/vi/sX2BeDzX8E0/maxresdefault.jpg`,
          title: 'CW Publications Overview'
        }
      ]
    );
  };

  const handleMathMediaClick = () => {
    openModal(
      MODAL_CONTENT.mathMedia.title,
      MODAL_CONTENT.mathMedia.cards,
      [
        {
          id: 'mathmedia/arithmetic_intro.png',
          thumbnailUrl: '/img/carousel_imgs/mathmedia/arithmetic_intro.png',
          title: 'Arithmetic Introduction',
        },
        {
          id: 'mathmedia/arithmetic_quiz.png',
          thumbnailUrl: '/img/carousel_imgs/mathmedia/arithmetic_quiz.png',
          title: 'Arithmetic Quiz',
        },
        {
          id: 'mathmedia/arithmetic_LMS.png',
          thumbnailUrl: '/img/carousel_imgs/mathmedia/arithmetic_LMS.png',
          title: 'Arithmetic LMS Integration',
        },
        {
          id: 'mathmedia/algebra_practice_screen.png',
          thumbnailUrl: '/img/carousel_imgs/mathmedia/algebra_practice_screen.png',
          title: 'Algebra Practice Screen',
        },
        {
          id: 'mathmedia/GED_sample_screen.jpg',
          thumbnailUrl: '/img/carousel_imgs/mathmedia/GED_sample_screen.jpg',
          title: 'GED Sample Screen',
        }
      ]
    );
  };

  const handleInstructionalSystemsClick = () => {
    openModal(
      MODAL_CONTENT.instructionalSystems.title,
      MODAL_CONTENT.instructionalSystems.cards
    );
  };

  const handleTechworks4GoodClick = () => {
    openModal(
      MODAL_CONTENT.techworks4Good.title,
      MODAL_CONTENT.techworks4Good.cards,
      [
        {
          id: '1MYhFD8Umd5kQftxP8ftZJe6Ew1Ty2fHR',
          thumbnailUrl: 'https://drive.google.com/thumbnail?id=1MYhFD8Umd5kQftxP8ftZJe6Ew1Ty2fHR',
          title: 'Reading XR Overview'
        },
        {
          id: 'NQ2h-q_ewP4',
          thumbnailUrl: 'https://img.youtube.com/vi/NQ2h-q_ewP4/0.jpg',
          title: 'Medical IP Overview',
          youtubeId: 'NQ2h-q_ewP4'
        },
        {
          id: 'rfPj8W5P75U',
          thumbnailUrl: 'https://img.youtube.com/vi/rfPj8W5P75U/maxresdefault.jpg',
          title: 'Immertec Overview',
          youtubeId: 'rfPj8W5P75U'
        },
        {
          id: 'DiTfJTByFek',
          thumbnailUrl: 'https://img.youtube.com/vi/DiTfJTByFek/maxresdefault.jpg',
          title: 'Resonai Overview',
          youtubeId: 'DiTfJTByFek'
        },
        {
          id: 'Qb_6JR4Dpxg',
          thumbnailUrl: 'https://img.youtube.com/vi/Qb_6JR4Dpxg/maxresdefault.jpg',
          title: 'Frontline.io Overview',
          youtubeId: 'Qb_6JR4Dpxg'
        },
        {
          id: 'jDQX4wlsJxc',
          thumbnailUrl: 'https://img.youtube.com/vi/jDQX4wlsJxc/maxresdefault.jpg',
          title: 'Ramblr.ai Overview',
          youtubeId: 'jDQX4wlsJxc'
        }
      ]
    );
  };

  const handleCG1SolutionsClick = () => {
    openModal(
      MODAL_CONTENT.cg1Solutions.title,
      MODAL_CONTENT.cg1Solutions.cards,
      [
        {
          id: 'sNmEeIvAtZw',
          thumbnailUrl: 'https://img.youtube.com/vi/sNmEeIvAtZw/maxresdefault.jpg',
          title: 'IQ3 Connect - Platform Overview',
          youtubeId: 'sNmEeIvAtZw'
        },
        {
          id: 'mK-_qzCmFfs',
          thumbnailUrl: 'https://img.youtube.com/vi/mK-_qzCmFfs/maxresdefault.jpg',
          title: 'Singular Health - 3Dicom VR Overview',
          youtubeId: 'mK-_qzCmFfs'
        }
      ]
    );
  };

  const handleFilmsMediaGroupClick = () => {
    openModal(
      MODAL_CONTENT.filmsMediaGroup.title,
      MODAL_CONTENT.filmsMediaGroup.cards,
      [
        {
          id: '1_hs9pqcli',
          thumbnailUrl: 'https://cfvod.kaltura.com/p/1067292/sp/106729200/thumbnail/entry_id/1_hs9pqcli/version/100031/width/375',
          title: 'The Age of Robots (Preview)',
          kaltura: 'https://cdnapisec.kaltura.com/p/1067292/sp/106729200/playManifest/clipTo/120000/entryId/1_hs9pqcli/flavorIds/1_wq6ipl82,1_qbxoqkcs,1_xhwc4i1q,1_2hy1wxyz,1_qmi8f89t/format/applehttp/protocol/https/a.m3u8?referrer=aHR0cHM6Ly93d3cuZmlsbXMuY29t&playSessionId=ac64f842-ea08-ad6a-7c7f-18d3c955e815&clientTag=html5:v2.101&preferredBitrate=1000&uiConfId=34527491'
        },
        {
          id: '1_oed0s59f',
          thumbnailUrl: 'https://cfvod.kaltura.com/p/1067292/sp/106729200/thumbnail/entry_id/1_oed0s59f/version/100051/width/375',
          title: 'Atlantis: A Lost History (Preview)',
          kaltura: 'https://cdnapisec.kaltura.com/p/1067292/sp/106729200/playManifest/clipTo/120000/entryId/1_oed0s59f/flavorIds/1_pmc310o4,1_fv65uarc,1_970d92h1,1_r2jop2ot,1_kvrxrknd,1_azdov2m5/format/applehttp/protocol/https/a.m3u8?referrer=aHR0cHM6Ly93d3cuZmlsbXMuY29t&playSessionId=71b337cc-0e06-aa72-155f-a7d6a87b524f&clientTag=html5:v2.101&preferredBitrate=1000&uiConfId=34527491'
        },
        {
          id: '1_zmijjd22',
          thumbnailUrl: 'https://cfvod.kaltura.com/p/1067292/sp/106729200/thumbnail/entry_id/1_zmijjd22/version/100051/width/375',
          title: 'Secrets of the Abyss (Preview)',
          kaltura: 'https://cdnapisec.kaltura.com/p/1067292/sp/106729200/playManifest/clipTo/120000/entryId/1_zmijjd22/flavorIds/1_38t1wx6g,1_o36nu5qd,1_1pzowc42,1_dtcf7zej,1_yf2wvezi,1_k7ugzhk2/format/applehttp/protocol/https/a.m3u8?referrer=aHR0cHM6Ly93d3cuZmlsbXMuY29t&playSessionId=8b4756e2-6cb5-ce84-ae39-4344b1106ddb&clientTag=html5:v2.101&preferredBitrate=1000&uiConfId=34527491'
        }
      ]
    );
  };

  return (
    <section id="solutions" ref={ref} className={`relative py-16 md:py-20 lg:py-24 overflow-hidden ${inView ? 'animate-fadeIn' : 'opacity-0'}`}>
      <div className="absolute inset-0 opacity-[0.15]">
        <div 
          className={`absolute inset-0 ${styles['animate-pattern']}`}
          style={{
            backgroundImage: `
              linear-gradient(45deg, transparent 47%, rgb(79, 70, 229) 48%, rgb(79, 70, 229) 52%, transparent 53%),
              linear-gradient(-45deg, transparent 47%, rgb(79, 70, 229) 48%, rgb(79, 70, 229) 52%, transparent 53%)
            `,
            backgroundSize: '70px 70px',
            backgroundPosition: '0 0'
          }}
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-white via-transparent via-55% to-white/80" />
      <div className="relative z-10 max-w-[1600px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center mb-12 md:mb-16">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-crimson font-bold text-[#1B3168] mb-4 md:mb-6">Our Solutions</h2>
          <p className="text-lg md:text-xl lg:text-2xl text-gray-600 font-[Inter]">Comprehensive tools and resources for modern education</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-6">

          {/* Technology & VR/AR/XR */}
          <div id="technology-vr" className="bg-gradient-to-b from-white to-white p-6 lg:p-8 xl:p-10 rounded-xl border border-gray-400 shadow-[0_10px_20px_-5px_rgba(79,70,229,0.35)] hover:shadow-[0_25px_35px_-12px_rgba(79,70,229,0.5)] transition-all duration-300 scroll-mt-24 md:scroll-mt-28 group hover:-translate-y-2 backdrop-blur-sm">
            <h3 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-900 text-center mb-2">Technology & VR/AR/XR</h3>
            <div className="text-center space-y-3">
              <div>
                <button 
                  onClick={handleTechworks4GoodClick}
                  className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                    Techworks 4 Good
                    <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                </button>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Pioneering breakthroughs in Technology</p>
              </div>
              <div>
                <button 
                  onClick={handleCG1SolutionsClick}
                  className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                    CG1 Solutions
                    <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                </button>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Advanced VR/AR/XR solutions</p>
              </div>
            </div>
          </div>

          {/* English Language Learning */}
          <div id="english-language" className="bg-gradient-to-b from-white to-white p-6 lg:p-8 xl:p-10 rounded-xl border border-gray-400 shadow-[0_10px_20px_-5px_rgba(79,70,229,0.35)] hover:shadow-[0_25px_35px_-12px_rgba(79,70,229,0.5)] transition-all duration-300 scroll-mt-24 md:scroll-mt-28 group hover:-translate-y-2 backdrop-blur-sm">
            <h3 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-900 text-center mb-2">English Language Learning</h3>
            <div className="text-center">
              <button 
                onClick={handleNYCEnglishClick}
                className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
              >
                <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                  NYC English
                  <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </svg>
                </span>
                <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
              </button>
              <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Dynamic English language learning platform</p>
            </div>
          </div>

          {/* Financial Literacy & Career Prep */}
          <div id="financial-literacy" className="bg-gradient-to-b from-white to-white p-6 lg:p-8 xl:p-10 rounded-xl border border-gray-400 shadow-[0_10px_20px_-5px_rgba(79,70,229,0.35)] hover:shadow-[0_25px_35px_-12px_rgba(79,70,229,0.5)] transition-all duration-300 scroll-mt-24 md:scroll-mt-28 group hover:-translate-y-2 backdrop-blur-sm">
            <h3 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-900 text-center mb-2">Financial Literacy & Career Prep</h3>
            <div className="text-center space-y-3">
              <div>
                <button 
                  onClick={handleMoneyVehicleClick}
                  className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                    Money Vehicle
                    <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                </button>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Financial literacy education</p>
              </div>
              <div>
                <button 
                  onClick={handleCWPublicationsClick}
                  className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                    CW Publications
                    <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                </button>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Career preparation resources</p>
              </div>
            </div>
          </div>

          {/* Other Academic Technologies */}
          <div id="other-academic" className="bg-gradient-to-b from-white to-white p-6 lg:p-8 xl:p-10 rounded-xl border border-gray-400 shadow-[0_10px_20px_-5px_rgba(79,70,229,0.35)] hover:shadow-[0_25px_35px_-12px_rgba(79,70,229,0.5)] transition-all duration-300 scroll-mt-24 md:scroll-mt-28 group hover:-translate-y-2 backdrop-blur-sm">
            <h3 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-900 text-center mb-2">Other Academic Technologies</h3>
            <div className="text-center space-y-3">
              <div>
                <button 
                  onClick={handleMathMediaClick}
                  className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                    MathMedia
                    <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                </button>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Interactive mathematics education tools</p>
              </div>
              <div>
                <button 
                  onClick={handleInstructionalSystemsClick}
                  className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                    Instructional Systems
                    <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                </button>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Comprehensive learning management solutions</p>
              </div>
              <div>
                <button 
                  onClick={handleFilmsMediaGroupClick}
                  className="company-button relative inline-flex items-center justify-center px-4 py-2.5 w-full bg-white hover:bg-indigo-50/80 rounded-lg group transition-all duration-300 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2 text-base md:text-lg lg:text-xl font-semibold text-indigo-600 group-hover:text-indigo-700">
                    Films Media Group
                    <svg className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-indigo-100/0 via-indigo-100/50 to-indigo-100/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                </button>
                <p className="text-sm md:text-base lg:text-lg text-gray-600 mt-2">Extensive library of educational videos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
